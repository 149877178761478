<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-space-between flex-wrap">
          <p>Events</p>
          <div class="event-filters d-flex justify-space-between">
            <v-select
              v-model="type"
              class="filter-input"
              :items="types"
              label="Type"
              clearable
            ></v-select>
            <v-dialog ref="dialog" v-model="dialog" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="filter-input"
                  v-model="dateRangeText"
                  label="Date range"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                  clearable
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-btn class="ma-2" dark color="primary" @click="paginate">
              Filter
            </v-btn>
          </div>
        </v-card-title>
        <v-data-table
          item-key="id"
          class="elevation-1 table-one"
          :headers="headers"
          :items="getEvents.length ? getEvents : []"
          :page="getEventsPage"
          :pageCount="getEventsPagesNumber"
          :items-per-page="10"
          :options.sync="options"
          :server-items-length="getEventsTotal"
          :loading="getEventsLoading"
          :disable-sort="true"
          :disable-filtering="true"
        >
          <template v-slot:item.created_at="{ item }">
            <div class="description">
              {{ item.created_at | datetime }}
            </div>
          </template>
          <template v-slot:item.user="{ item }">
            {{ item.user | userName }}
          </template>
          <template v-slot:item.description="{ item }">
            <div>{{ item.description | parse }}</div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getTypes } from "@/services/eventService";
import _ from "lodash";
import moment from "moment";

export default {
  metaInfo: {
    title: "Event"
  },
  data() {
    return {
      model: "event",
      page: null,
      dates: [],
      types: [],
      type: null,
      selected: null,
      shouldRemoveModel: false,
      dialog: false,
      options: {},
      headers: [
        { text: "Created", value: "created_at" },
        { text: "User", value: "user" },
        { text: "Description", value: "description" }
      ]
    };
  },
  mounted() {
    this.page = this.$store.getters.getPage({ model: this.model });
    this.getTypes();
  },
  methods: {
    ...mapActions(["setEvents"]),
    async getTypes() {
      try {
        const { data } = await getTypes();
        this.types = _.map(data, item => item.code);
      } catch (error) {
        console.error(error);
      }
    },
    paginate() {
      const { page, itemsPerPage } = this.options;
      this.setEvents({
        page,
        perPage: itemsPerPage,
        type: this.type,
        dates: this.sortedDates
      });
    }
  },
  computed: {
    ...mapGetters([
      "getEvents",
      "getEventsPage",
      "getEventsTotal",
      "getEventsPagesNumber",
      "getEventsLoading"
    ]),
    dateRangeText: {
      get() {
        return this.dates ? this.dates.join(" ~ ") : "";
      },
      set(value) {
        return value;
      }
    },
    sortedDates() {
      const dates =
        _.size(this.dates) === 1
          ? [...this.dates, _.get(this.dates, "0")]
          : this.dates;

      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    }
  },
  watch: {
    options: {
      handler() {
        this.paginate();
      }
    },
    deep: true
  },
  filters: {
    parse(value) {
      try {
        const data = JSON.parse(value);
        return Object.keys(data)
          .map(key => `${key}: ${data[key]}`)
          .join(", ");
      } catch (error) {
        return value;
      }
    },
    userName(value) {
      return _.get(value, "name", "—");
    },
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    }
  }
};
</script>
<style lang="scss" scoped>
.event-filters {
  .filter-input {
    margin: 0 0.5rem;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }

      .description {
        min-width: 120px;
      }

      td {
        &:nth-child(3) {
          word-break: break-word;
          word-wrap: break-word;

          div {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
