var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('p',[_vm._v("Events")]),_c('div',{staticClass:"event-filters d-flex justify-space-between"},[_c('v-select',{staticClass:"filter-input",attrs:{"items":_vm.types,"label":"Type","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"filter-input",attrs:{"label":"Date range","clearable":"","readonly":""},on:{"click:clear":function($event){_vm.dates = []}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.paginate}},[_vm._v(" Filter ")])],1)]),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.getEvents.length ? _vm.getEvents : [],"page":_vm.getEventsPage,"pageCount":_vm.getEventsPagesNumber,"items-per-page":10,"options":_vm.options,"server-items-length":_vm.getEventsTotal,"loading":_vm.getEventsLoading,"disable-sort":true,"disable-filtering":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm._f("datetime")(item.created_at))+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("userName")(item.user))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("parse")(item.description)))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }